<!--  -->
<template>
  <div class="container">
    <!-- <div class="config">
      <p class="title">辖区信息</p>
      <div class="btn-grounp">
        <el-button class="import">导入数据</el-button>
        <el-button class="management-config">管理配置</el-button>
      </div>
      <div class="search">
        <el-input class="search-input" placeholder="搜索" suffix-icon="el-icon-search" v-model="input1"></el-input>
      </div>
    </div>
    <div class="content">
        <div class="no-data">
            <img src="./img/no-data.svg" alt="">
            <p>当前暂无生效辖区数据，请导入数据并在管理配置中设置生效</p>
        </div>
    </div> -->

    <div class="title">辖区信息</div>
    <div class="btn-grounp">
      <el-button class="import" @click="handleImport">导入数据</el-button>
      <el-button class="management-config" @click="handleConfig">管理配置</el-button>
    </div>
    <div class="table">
      <eyao-table
        :table-data="tableData"
        :total-num="num"
        :loading="loading"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :select-page-size="10"
      >
        <el-table-column
          min-width="260"
          v-for="item in tableDataHead"
          :key="item"
          align="center"
          :prop="item"
          :label="item"
        ></el-table-column>
        <template v-slot:empty>
          <div class="no-data" v-if="!tableData.length && !loading">
            <img src="./img/no-data.svg" alt="" />
            <p>当前暂无生效辖区数据，请导入数据并在管理配置中设置生效</p>
          </div>
        </template>
      </eyao-table>
      <!-- <div class="no-data" v-if="!tableData.length && !loading">
        <img src="./img/no-data.svg" alt="" />
        <p>当前暂无生效辖区数据，请导入数据并在管理配置中设置生效</p>
      </div> -->
    </div>

    <!-- 数据导入model -->
    <el-dialog
      title="数据导入"
      :visible.sync="importDialog"
      width="800px"
      class="dataImport-dialog"
      :before-close="closeDataImportDialog"
    >
      <div class="dataImport-warp">
        <div class="dataImport-warp-step">
          <el-steps :active="dataImportActive" align-center>
            <el-step title="上传导入文件"></el-step>
            <el-step title="设置匹配规则"></el-step>
            <el-step title="导入数据预览"></el-step>
          </el-steps>
        </div>

        <!-- 第一步，上传 -->
        <div class="dataImport-first-step" v-if="dataImportActive === 1">
          <el-upload
            class="dataImport-upload"
            ref="dataImport-upload"
            drag
            :limit="1"
            action="#"
            :file-list="uploadFileList"
            :before-upload="beforeUpload"
            :on-change="hanldeUploadChange"
            :on-remove="hanldeDelUploadList"
            :http-request="uploadHttpRequest"
          >
            <template v-if="!hideUploadTips">
              <i class="el-icon-plus"></i>
              <div class="el-upload__text">点击或拖拽文件到此处上传</div>
            </template>
            <div class="el-upload__tip" :class="{ 'tip-mt-140': hideUploadTips }">
              后缀为.xlsx的excel文件；列名在文件第一行；数据不能跨行，不能包含空行
            </div>
          </el-upload>
          <!-- 进度条 -->
          <el-progress
            v-if="showProgress"
            :percentage="progressPercent"
            :color="colors"
          ></el-progress>

          <div class="dataImport-downTemp" @click="handleDownloadTemp">下载导入模板</div>
        </div>

        <!-- 第二步，设置匹配规则 -->
        <div class="dataImport-second-step" v-if="dataImportActive === 2">
          <div class="dataImport-second-step-file-name">
            <i class="el-icon-document"></i>
            {{ uploadFileName }}
          </div>
          <div class="match-rule">
            <el-table
              v-loading="importLoading"
              :data="ruleData"
              :header-cell-style="headercellstyle"
              :cell-style="{ background: '#fff' }"
            >
              <el-table-column label="文字展示字符" align="center">
                <template slot-scope="scope">
                  <el-select
                    clearable
                    :disabled="scope.row.required"
                    v-model="scope.row.tar_field_name"
                    placeholder="请选择"
                    @change="
                      val => {
                        handleChange(val, scope.$index);
                      }
                    "
                  >
                    <el-option
                      v-for="item in Targetoption"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                </template>
              </el-table-column>
              <el-table-column label="目标字段" align="center" prop="tar_field"></el-table-column>
              <el-table-column label="源字段" align="center">
                <template slot-scope="scope">
                  <div class="tab-row">
                    <el-select v-model="scope.row.src_field" placeholder="请选择" clearable>
                      <el-option
                        v-for="item in Sourceoptions"
                        :key="item"
                        :label="item"
                        :value="item"
                      ></el-option>
                    </el-select>
                    <div class="del" v-if="!scope.row.required">
                      <i @click="handleDel(scope.row)"></i>
                    </div>
                  </div>
                </template>
              </el-table-column>
            </el-table>
            <template>
              <div class="add">
                <!-- <img src="./img/add.svg" alt="" /> -->
                <el-button
                  icon="el-icon-plus"
                  type="text"
                  @click="handleAdd"
                  :disabled="ruleData.length === 26"
                >
                  添加条件
                </el-button>
              </div>
            </template>
          </div>
        </div>

        <!-- 第三步，预览 -->
        <div class="dataImport-second-step" v-if="dataImportActive === 3">
          <div class="dataImport-second-step-file-name">
            <i class="el-icon-document"></i>
            {{ uploadFileName }}
          </div>
          <div class="dataImport-second-step-file-info">
            <div class="file-info-left">
              <div class="file-info-type">
                数据类型:
                <span>辖区</span>
              </div>
              <div class="file-info-num">
                导入数据量:
                <span>{{ previewUplaodNum }}</span>
              </div>
            </div>
            <div class="file-info-right">
              <span>预览仅展示前20条数据</span>
            </div>
          </div>
          <div class="preview-data">
            <el-table
              :header-cell-style="headercellstyle"
              :cell-style="{ background: '#fff' }"
              :data="previewData"
              v-loading="previewLoading"
            >
              <el-table-column
                min-width="200"
                v-for="item in header"
                :key="item"
                :label="item"
                :prop="item"
              ></el-table-column>
            </el-table>
          </div>
        </div>

        <!-- 下一步、上一步、取消按钮 -->
        <div class="dataImport-footer">
          <template v-if="dataImportActive === 1">
            <el-button
              class="dataImport-button dataImport-cancelBtn"
              @click="closeDataImportDialog"
            >
              取消
            </el-button>
            <el-button
              class="dataImport-button dataImport-nextBtn"
              :disabled="uploadNextStep"
              type="primary"
              @click="dataImportNext"
            >
              下一步
            </el-button>
          </template>
          <template v-if="dataImportActive === 2">
            <el-button
              class="dataImport-button dataImport-cancelBtn"
              @click="closeDataImportDialog"
            >
              取消
            </el-button>
            <el-button
              v-auto-unfocus
              class="dataImport-button dataImport-preBtn"
              @click="dataImportActive = 1"
            >
              上一步
            </el-button>
            <el-button
              class="dataImport-button dataImport-nextBtn"
              type="primary"
              @click="handleConfigNext"
            >
              下一步
            </el-button>
          </template>
          <template v-if="dataImportActive === 3">
            <el-button
              v-auto-unfocus
              class="dataImport-button dataImport-preBtn"
              @click="dataImportActive = 2"
            >
              上一步
            </el-button>
            <el-button
              slot="reference"
              v-auto-unfocus
              class="dataImport-button dataImport-comfirmBtn"
              type="primary"
              @click="comfirmImportData"
            >
              确定导入
            </el-button>
          </template>
        </div>
      </div>
    </el-dialog>

    <!-- 管理配置 -->
    <el-drawer :size="640" title="管理配置" :visible.sync="configDrawer" custom-class="drawer">
      <div class="info">
        <p class="info-title">数据信息</p>
        <div class="info-data">
          <span>数据创建人：{{ createUser }}</span>
          <span>创建时间：{{ createTime }}</span>
        </div>
        <div class="info-data">
          <span>数据条数：{{ dataCount }}</span>
          <span>辖区配置状态：{{ actived === true ? '已生效' : '未生效' }}</span>
        </div>
      </div>

      <div class="config-table">
        <p class="title">映射规则</p>
        <div>
          <el-table
            :header-cell-style="headercellstyle"
            :cell-style="{ background: '#fff' }"
            :data="configPreviewData"
            v-loading="configPreviewLoading"
          >
            <el-table-column
              label="目标字段名称"
              align="center"
              prop="tar_field_name"
            ></el-table-column>
            <el-table-column label="目标字段" align="center" prop="tar_field"></el-table-column>
            <el-table-column label="源字段" align="center" prop="src_field"></el-table-column>
          </el-table>
        </div>
      </div>
      <div class="btn">
        <el-button class="btn1" @click="handleEffect(true)" v-if="actived === false">
          设为生效
        </el-button>
        <el-button class="btn1" @click="handleEffect(false)" v-if="actived === true">
          设为失效
        </el-button>
        <!-- <el-button class="btn2" @click="handleImport">导入数据</el-button> -->
      </div>
    </el-drawer>
  </div>
</template>

<script>
import eyaoTable from '@/components/table';
import {
  PotentialMapGetMatchRuleInfo,
  PotentialMapGetPreviewData,
  CreatePotentialMapDataImportTask,
  GetJurisdictionConfigInfo,
  ActiveJurisdictionData,
  GetJurisdictionData,
} from '@/api/jurisdiction';
import { getBlob, downloadFile } from '@/utils/downloadExcel';
import { GetDataImportTemplate } from '@/api/data';
import { GetObjURL } from '@/api/upload';
export default {
  components: {
    eyaoTable,
  },
  data() {
    return {
      headercellstyle: {
        background: '#F2F3F8',
        'box-shadow': 'inset 0px -1px 0px #E5EAF2',
        height: '38px',
      },
      tableData: [],
      tableDataHead: [],
      num: 0,
      loading: true,
      importLoading: false,
      uploadCosUrl: '',
      importDialog: false,
      previewLoading: false,
      // 数据导入步骤条
      dataImportActive: 1,
      uploadFileList: [],
      hideUploadTips: false,
      progressPercent: 20,
      uploadNextStep: true,
      showProgress: false,
      uploadFileName: '',
      previewUplaodNum: 0,
      ruleData: [],
      Targetoptions: [],
      Sourceoptions: [],
      tarFields: [],
      previewData: [],
      header: [],
      configDrawer: false,
      configPreviewData: [], // 管理配置映射表格数据
      actived: '', // 是否生效
      dataCount: 0, // 导入数据量
      createUser: '', // 创建人
      createTime: '', // 创建时间
      configPreviewLoading: false,
      index: 1,
      page: 10,
      colors: [
        { color: '#0069f9', percentage: 30 },
        { color: '#23b712', percentage: 100 },
      ],
    };
  },
  computed: {
    Targetoption() {
      const arr = this.ruleData.map(item => {
        return item.tar_field_name;
      });
      return this.Targetoptions.filter(item => {
        return !arr.includes(item.value);
      });
    },
  },
  created() {
    this.getData();
  },
  methods: {
    getData() {
      this.loading = true;
      const req = {
        index: this.index,
        page_size: this.page,
      };
      GetJurisdictionData(req)
        .then(res => {
          this.tableData = res.datas.map(item => {
            return { ...JSON.parse(item) };
          });
          this.tableDataHead = res.headers;
          this.num = res.total;
          this.loading = false;
        })
        .catch(err => {
          this.$message.error(err.message);
          this.loading = false;
        });
    },
    handleSizeChange(val) {
      console.log(val);
      this.page = val;
      this.getData();
    },
    handleCurrentChange(val) {
      console.log(val);
      this.index = val;
      this.getData();
    },
    handleImport() {
      this.importDialog = true;
    },
    closeDataImportDialog() {
      this.importDialog = false;
      this.progressPercent = 20;
      this.uploadFileList = [];
      this.hideUploadTips = false;
      this.dataImportActive = 1;
      this.uploadNextStep = true;
      this.showProgress = false;
    },
    // 上传文件之前的检测
    beforeUpload(file) {
      // console.log(file, 'beforeUpload');
      const _this = this;
      return new Promise(function(resolve, reject) {
        const fileType = file.name.substr(file.name.indexOf('.') + 1);
        const xls = ['xls', 'xlsx'];
        if (xls.includes(fileType)) {
          resolve('校验成功!');
        } else {
          _this.$message.error('请上传后缀为.xlsx的excel文件');
          _this.hideUploadTips = false;
          reject(false);
        }
      });
    },
    // 改变上传文件
    hanldeUploadChange(file, fileList) {
      // console.log(file, fileList);
      if (fileList.length > 0) {
        this.uploadFileList = [fileList[fileList.length - 1]]; // 展示最后一次选择的文件
      }
    },
    // 删除上传的文件
    hanldeDelUploadList(file, fileList) {
      // console.log(file);
      this.uploadFileList = [];
      if (file?.name) {
        this.hideUploadTips = false;
        this.showProgress = false;
        this.progressPercent = 20;
      }
      if (fileList.length < 1) {
        this.uploadNextStep = true;
      }
    },
    uploadHttpRequest(param) {
      console.log(param);
      if (param.file?.name) {
        this.hideUploadTips = true;
        this.showProgress = true;
      }
      const _this = this;
      this.putCosObject({ fileObject: param.file }).then(res => {
        console.log(res, 'putCosObject');
        if (res?.data) {
          const { percent } = JSON.parse(res.progress);
          if (percent === 1) {
            _this.uploadCosUrl = res.data;
            _this.uploadFileName = param.file.name;

            setTimeout(() => {
              _this.progressPercent = 100;
              _this.uploadNextStep = false;
            }, 500);
          }
        }
      });
    },
    // 下载导入模板
    handleDownloadTemp() {
      GetDataImportTemplate({ data_type: 'potential_map_data' }).then(respoese => {
        console.log(respoese);
        if (respoese?.file_name) {
          GetObjURL({ url: respoese.url }).then(res => {
            console.log('转化后', res);
            getBlob(res.data.url, function(blob) {
              downloadFile(blob, respoese.file_name);
            });
          });
        }
      });
    },
    dataImportNext() {
      this.dataImportActive = 2;
      this.importLoading = true;
      PotentialMapGetMatchRuleInfo({ src_file: this.uploadCosUrl })
        .then(res => {
          // console.log(res);
          this.Sourceoptions = res.src_fields;
          this.ruleData = res.tar_fields.map(item => {
            return { ...item, src_field: '' };
          });
          this.tarFields = res.tar_fields;
          console.log(this.tarFields);
          res.tar_fields.forEach(item => {
            this.Targetoptions.push({
              label: item.tar_field_name,
              value: item.tar_field_name,
            });
          });
          this.importLoading = false;
        })
        .catch(err => {
          this.$message.error(err.message);
        });
    },
    handleConfigNext() {
      console.log(this.ruleData);
      if (
        this.ruleData.some(item => {
          if (item.tar_field_name === '' || item.src_field === '') {
            return true;
          }
        })
      ) {
        this.$message.error('目标字段和源字段不可为空，请检查后重新提交');
        return;
      }
      this.dataImportActive = 3;
      this.previewLoading = true;
      const req = {
        src_file: this.uploadCosUrl,
        match_rule: this.ruleData,
      };
      PotentialMapGetPreviewData(req)
        .then(res => {
          console.log(res);
          this.previewData = res.datas.map(item => {
            return { ...JSON.parse(item) };
          });
          this.header = res.headers;
          this.previewUplaodNum = res.total;
          this.previewLoading = false;
        })
        .catch(err => {
          this.$message.error(err.message);
        });
    },
    comfirmImportData() {
      const req = {
        headers: this.header,
        src_file: this.uploadCosUrl,
        match_rule: this.ruleData,
        confirm: false,
      };
      CreatePotentialMapDataImportTask(req)
        .then(res => {
          console.log(res);
          if (res.has_task_executing) {
            this.$confirm('已有辖区导入任务正在执行中, 是否继续?', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning',
            })
              .then(() => {
                const req = {
                  headers: this.header,
                  src_file: this.uploadCosUrl,
                  match_rule: this.ruleData,
                  confirm: true,
                };
                CreatePotentialMapDataImportTask(req)
                  .then(res => {
                    console.log(res);
                    this.importDialog = false;
                    this.$message.success('导入任务已生成');
                  })
                  .catch(err => {
                    this.$message.error(err.message);
                  });
              })
              .catch(() => {});
          } else {
            this.importDialog = false;
            this.$message.success('导入任务已生成');
          }
        })
        .catch(err => {
          this.$message.error(err.message);
        });
    },
    handleAdd() {
      if (this.ruleData.length < 26) {
        this.ruleData.push({
          tar_field_name: '',
          tar_field: '',
          src_field: '',
        });
      }
    },
    handleDel(scope) {
      this.ruleData = this.ruleData.filter(item => {
        return item.tar_field_name !== scope.tar_field_name;
      });
    },
    handleChange(val, index) {
      this.tarFields.forEach(item => {
        if (item.tar_field_name === val) {
          this.ruleData[index].tar_field = item.tar_field;
        }
      });
    },

    // 打开管理配置抽屉
    handleConfig() {
      this.configDrawer = true;
      this.configPreviewLoading = true;
      GetJurisdictionConfigInfo({})
        .then(res => {
          console.log('信息', res);
          this.configPreviewData = res.match_rule;
          this.actived = res.actived;
          this.dataCount = res.data_count;
          this.createUser = res.create_user;
          this.createTime = res.create_time;
          this.configPreviewLoading = false;
        })
        .catch(err => {
          this.$message.error(err.message);
        });
    },
    // 设置生效
    handleEffect(val) {
      console.log(val);
      const req = {
        active: val,
      };
      if (val) {
        this.$confirm('确认将该版本辖区信息置为生效?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(() => {
          ActiveJurisdictionData(req)
            .then(() => {
              this.$message.success('状态设置成功');
              GetJurisdictionConfigInfo({})
                .then(res => {
                  console.log('信息', res);
                  this.configPreviewData = res.match_rule;
                  this.actived = res.actived;
                  this.dataCount = res.data_count;
                  this.createUser = res.create_user;
                  this.createTime = res.create_time;
                  this.configPreviewLoading = false;
                  this.getData();
                })
                .catch(err => {
                  this.$message.error(err.message);
                });
            })
            .catch(err => {
              this.$message.error(err.message);
            });
        });
      } else {
        this.$confirm('确认将该版本辖区信息置为失效?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(() => {
          ActiveJurisdictionData(req)
            .then(() => {
              this.$message.success('状态设置成功');
              GetJurisdictionConfigInfo({})
                .then(res => {
                  console.log('信息', res);
                  this.configPreviewData = res.match_rule;
                  this.actived = res.actived;
                  this.dataCount = res.data_count;
                  this.createUser = res.create_user;
                  this.createTime = res.create_time;
                  this.configPreviewLoading = false;
                  this.getData();
                })
                .catch(err => {
                  this.$message.error(err.message);
                });
            })
            .catch(err => {
              this.$message.error(err.message);
            });
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import './index.scss';
</style>
