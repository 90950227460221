<template>
  <div class="table-pagination-warp">
    <el-table
      empty-text=" "
      :class="{ table: true, hiddenScroll: false }"
      :header-cell-style="headercellstyle"
      ref="table"
      :data="tableData"
      v-loading="loading"
      style="width: 100%"
      :row-key="rowKeyMeow"
      :header-row-style="{ height: '46px', color: '#667082' }"
      :row-style="{ height: '46px' }"
      :cell-style="{ padding: '0px' }"
      @scroll="handleScroll"
      @selection-change="handleSelectionChange"
      @filter-change="handleFilterChange"
      @header-dragend="handleHeaderDragend"
      @sort-change="hanldeSortChange"
    >
      <slot></slot>
      <template v-slot:append>
        <slot name="append"></slot>
      </template>
      <template v-slot:empty>
        <slot name="empty"></slot>
      </template>
    </el-table>

    <div v-if="tableData.length > 0 && paginationShow" class="pagination">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="1"
        :page-sizes="pageSizes"
        :page-size="selectPageSize"
        :layout="layout"
        :total="totalNum"
      ></el-pagination>
    </div>
  </div>
</template>
<script>
import { PAGE_SIZE, PAGE_SIZES } from '@/utils/constant';

export default {
  components: {},
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
    height: {
      type: String,
      default: '100%',
    },
    // 隐藏滚动条 如果有固定列阻挡滚动条的显示 可以关掉滚动条 由于使用鼠标的用户可能无法横向滚动 暂时不开启这个功能
    hiddenScroll: {
      type: Boolean,
      default: false,
    },
    // 是否触发加载更多事件
    needLoading: {
      type: Boolean,
      default: true,
    },
    queryType: {
      type: String,
      default: 'hospital',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    totalNum: {
      type: Number,
      default: 0,
    },
    paginationShow: {
      type: Boolean,
      default: true,
    },
    heightSty: {
      type: String,
      default: '',
    },
    selectPageSize: {
      type: Number,
      default: 20,
    },
    layout: {
      type: String,
      default: 'total, prev, pager, next, sizes, jumper',
    },
  },
  data() {
    return {
      headercellstyle: {
        background: '#F2F3F8',
        'box-shadow': 'inset 0px -1px 0px #E5EAF2',
        padding: 0,
      },
      // 记录表格body元素
      scrollElement: null,
      // 滚动计时器 每次滚动成功时触发 结束时判断是否滚动到底
      scrollTimer: null,
      // 滚动延时 每次滚动成功后一段时间内屏蔽滚动事件
      scrollDelayTimer: null,
      // 如果高度不足以触发滚动 就自动触发加载更多事件 直到父组件阻止
      autoLoadingTimer: null,
      pageSize: PAGE_SIZE,
      pageSizes: PAGE_SIZES,
    };
  },
  mounted() {
    // 标记找到的body表格元素打上滚动监听器
    // [this.scrollElement] = this.$refs.table.$el.getElementsByClassName('el-table__body-wrapper');
    // this.scrollElement.addEventListener('scroll', this.handleScroll);
    // // 防止加载完成时数据过少元素高度不足 直接显示加载中的底栏 同时无法滚动触发加载事件
    // this.autoLoadingTimer = setInterval(() => {
    //   const { clientHeight, scrollHeight } = this.scrollElement;
    //   if (this.needLoading && clientHeight === scrollHeight) {
    //     this.$emit('loading-more');
    //   }
    // }, 3000);
  },
  // beforeDestroy() {
  //   this.scrollElement.removeEventListener('scroll', this.handleScroll);
  //   clearInterval(this.autoLoadingTimer);
  // },
  methods: {
    handleScroll() {
      if (this.needLoading === false) {
        return;
      }
      // 事件触发延时
      if (this.scrollDelayTimer !== null) return;
      this.scrollDelayTimer = setTimeout(() => {
        clearTimeout(this.scrollDelayTimer);
        this.scrollDelayTimer = null;
      }, 100);

      // 滚动时间触发后延时到滚动完成再确认当前位置是否显示加载中的低栏
      this.scrollTimer = setTimeout(() => {
        this.scrollTimer = null;
        const { scrollTop, clientHeight, scrollHeight } = this.scrollElement;
        // ===无法触发，子元素height带有浮点数，所以改成 >=
        if (scrollTop + clientHeight >= scrollHeight) {
          // 由于在加载完成前表格高度固定 可能会有多次触发该事件的可能性 需要父组件在加载完成前对重复事件进行屏蔽
          this.$emit('loading-more');
        }
      }, 250);
    },

    // pageSize 改变
    handleSizeChange(val) {
      this.$emit('size-change', val);
    },

    // currentPage 改变
    handleCurrentChange(val) {
      this.$emit('current-change', val);
    },

    rowKeyMeow(row) {
      if (this.$route.name === 'user-manage') {
        return new Date().getTime() + String(row.phone);
      }
      if (this.$route.name === 'action-log' || this.$route.name === 'login-log') {
        return new Date().getTime() + String(row.log_id);
      }
      if (this.$route.name === 'import-export' || this.$route.name === 'doctor-match') {
        return row.task_id
          ? new Date().getTime() + String(row.task_id)
          : new Date().getTime() + String(row.id);
      }
      if (this.$route.name === 'doctor-match-result') {
        return row.resultId ? row.resultId : row.name;
      }
      if (this.$route.name === 'tag-manage-detail') {
        return row.id ? row.id : row.record_id;
      }
      if (this.$route.name === 'dcrRecord-detail') {
        return row.id ? row.id : row.fieldName;
      }
      if (this.$route.name === 'jurisdiction-management') {
        return row['机构编码'];
      }
      return new Date().getTime() + String(row.id) + String(row.log_id);
    },
    // 将element表格的事件传递给父组件
    handleSelectionChange(data) {
      this.$emit('selection-change', data);
    },
    handleFilterChange(filter) {
      this.$emit('filter-change', filter);
    },
    handleHeaderDragend(newW, oldW, col, e) {
      this.$emit('header-dragend', newW, oldW, col, e);
    },
    hanldeSortChange(column) {
      this.$emit('sort-change', column);
    },
    // 用于链接父组件与element表格组件接口的示例 父组件调用表格方法的流程不变 直接通过本组件ref调用相应的函数即可
    clearFilter(filter) {
      this.$refs.table.clearFilter(filter);
    },
    clearSelection() {
      this.$refs.table.clearSelection();
    },
    doLayout() {
      this.$refs.table.doLayout();
    },
    // 返回表格的vue对象 用于直接获取数据或进行操作
    control() {
      return this.$refs.table;
    },
  },
};
</script>
<style lang="scss" scoped>
@import './table.scss';
</style>
