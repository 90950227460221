import request from '@/libs/request';

export const SearchMainData = data => request('MdmAdmin/DataService/SearchMainData', data);
export const SearchMainDataByType = data =>
  request('MdmAdmin/DataService/SearchMainDataByType', data);
export const GetMainDataListByType = data =>
  request('MdmAdmin/DataService/GetMainDataListByType', data);
export const GetStagingDataListByType = data =>
  request('MdmAdmin/DataService/GetStagingDataListByType', data);
export const EditMainDataListPage = data =>
  request('MdmAdmin/DataService/EditMainDataListPage', data);
export const GetMdmDataDetail = data => request('GetMdmDataDetail', data);
export const EditMainDataDetail = data => request('MdmAdmin/DataService/EditMainDataDetail', data);
export const CreateMainDataDetail = data =>
  request('MdmAdmin/DataService/CreateMainDataDetail', data);
export const GetDictValue = data => request('MdmAdmin/DataService/GetDictValue', data);
export const GetDictOptionList = data => request('MdmAdmin/DataService/GetDictOptionList', data);
export const GetOrgType = data => request('MdmAdmin/DataService/GetOrgType', data);
export const GetAdminDistrict = data => request('MdmAdmin/DataService/GetAdminDistrict', data);
export const GetMainDataDetail = data => request('MdmAdmin/DataService/GetMainDataDetail', data);
export const DeleteMainDataDetail = data =>
  request('MdmAdmin/DataService/DeleteMainDataDetail', data);
export const GetOrgTypeList = data => request('MdmAdmin/DataService/GetOrgTypeList', data);
export const GetStagingDataDetail = data =>
  request('MdmAdmin/DataService/GetStagingDataDetail', data);
export const GetStandardDeptList = data =>
  request('MdmAdmin/DataService/GetStandardDeptList', data);
export const GetOpeLogListByDataid = data =>
  request('MdmAdmin/DataManager/GetOpeLogListByDataid', data);

// 导出任务
export const CreateDataExportTask = data =>
  request('MdmAdmin/TaskService/CreateDataExportTask', data);
export const GetDataImportExportTaskList = data =>
  request('MdmAdmin/TaskService/GetDataImportExportTaskList', data);
export const GetDataImportExportTaskLog = data =>
  request('MdmAdmin/TaskService/GetDataImportExportTaskLog', data);

// 医生数据匹配
export const GetDoctorDataMatchTaskList = data =>
  request('MdmAdmin/TaskService/GetDoctorDataMatchTaskList', data);
export const CreateDoctorDataMatchTask = data =>
  request('MdmAdmin/TaskService/CreateDoctorDataMatchTask', data);
export const GetDoctorDataMatchTaskResult = data =>
  request('MdmAdmin/TaskService/GetDoctorDataMatchTaskResult', data);

// 导入任务
export const GetDataImportTemplate = data =>
  request('MdmAdmin/TaskService/GetDataImportTemplate', data);
export const CreateDataImportTask = data =>
  request('MdmAdmin/TaskService/CreateDataImportTask', data);
export const GetDataImportPreviewData = data =>
  request('MdmAdmin/TaskService/GetDataImportPreviewData', data);

// 全文搜索条数及数据上报
export const SearchAllCount = data => request('MdmAdmin/DataService/SearchAllCount', data);
export const DataReport = data => request('MdmAdmin/DataService/DataReport', data);

// 获取权限
export const GetFieldPermissions = data => request('MdmAdmin/User/GetFieldPermissions', data);

// 导出历史版本
export const GetDataHistory = data => request('MdmAdmin/DataManager/GetDataHistory', data);

// 查询历史版本详情
export const GetDataHistoryDetail = data =>
  request('MdmAdmin/DataManager/GetDataHistoryDetail', data);
