import request from '@/libs/request';

export const PotentialMapGetMatchRuleInfo = data =>
  request('MdmAdmin/DataStatisticsService/PotentialMapGetMatchRuleInfo', data);
export const PotentialMapGetPreviewData = data =>
  request('MdmAdmin/DataStatisticsService/PotentialMapGetPreviewData', data);
export const CreatePotentialMapDataImportTask = data =>
  request('MdmAdmin/TaskService/CreatePotentialMapDataImportTask', data);
export const GetJurisdictionConfigInfo = data =>
  request('MdmAdmin/DataStatisticsService/GetJurisdictionConfigInfo', data);
export const ActiveJurisdictionData = data =>
  request('MdmAdmin/DataStatisticsService/ActiveJurisdictionData', data);
export const GetJurisdictionData = data =>
  request('MdmAdmin/DataStatisticsService/GetJurisdictionData', data);
